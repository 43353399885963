import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthentificationService } from '../../service/authentification.service';
import {BehaviorSubject, Observable} from 'rxjs';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { HttpService } from '../../service/http.service';
import {HistoriqueFluxPj} from "../../modele/historique-flux-pj";
import {ProfilAcces} from "../../modele/profil-acces";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {
  id: any;
  postIdSource = new BehaviorSubject<number>(0);
  constructor(public http: HttpClient, public httpServ: HttpService, public datepipe: DatePipe) {
    this.id = this.postIdSource.asObservable();
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 50) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    //let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    let endIndex = 49;

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  count() {
    return new Promise(resolve => {
      this.http.get('utilisateur/count').subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  getUtilisateur(first: number, max: number) {
    return new Promise(resolve => {
      this.http.get('utilisateur/findall?first=' + first + '&max=' + max).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  getUtilisateurs() {
    console.log("AAAAA")
    return new Promise(resolve => {
      this.http.get('utilisateur/findallNonPagine').subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  detail(id) {
    return new Promise(resolve => {
      //let currentUser = this.authenticationService.currentUserValue;
      // let headers = new HttpHeaders();
      // headers.append('Authorization','Bearer '+currentUser.token);
      this.http.get('utilisateur/detail/' + id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  getProfil() {
    return new Promise(resolve => {
      this.http.get('profile/findall?first=0&max=10').subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  getActivite() {
    return new Promise(resolve => {
      this.http.get('activite/findall').subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  getActiviteDetail(id) {
    return new Promise(resolve => {
      this.http.get('activite/detail/'+id).subscribe((data:any) => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  getProfile(id) {
    return new Promise(resolve => {
      this.http.get('profile/detail/'+id).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  ajout(name, mail, fonction, activate, activiteSingle, activiteMultiple, profil, loginad, listboxMultipleActivites) {
    if (!listboxMultipleActivites) {
      return new Promise(resolve => {
        this.getActiviteDetail(parseInt(activiteSingle)).then((activiteObj: any) => {
          let activite: any = [];
          activite.push({ "id": activiteSingle, "name": activiteObj.data.name });
          let dataa: any = {
            "name": name,
            "mail": mail,
            "function": fonction,
            "activites": activite,
            "activate": activate,
            "loginad": loginad,
            "profil": { "id": profil }
          };
          console.log("==================== data to insert =", dataa);
          let headers = new HttpHeaders();
          headers.append('Content-Type', 'text/plain;charset=UTF-8');
          headers.append('Content-Type', 'application/json; charset=utf-8');
          headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
          this.http.post('utilisateur/ajout', dataa, { headers: headers }).subscribe(data => {
            resolve(data);
          }, err => {
            console.log("================== erreur dans ajout :", err);
          });
        });
      });
    }
    else {
      let dataa: any = {
        "name": name,
        "mail": mail,
        "function": fonction,
        "activites": activiteMultiple,
        "activate": activate,
        "profil": { "id": profil }
      };
      console.log("==================== data to insert =", dataa);
      return new Promise(resolve => {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'text/plain;charset=UTF-8');
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
        this.http.post('utilisateur/ajout', dataa, { headers: headers }).subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }
  }
  modification(id, name, fonction, activiteSingle, activiteMultiple, profil, listboxMultipleActivites) {
    if (!listboxMultipleActivites) {
      return new Promise(resolve => {
        this.getActiviteDetail(parseInt(activiteSingle)).then((activiteObj: any) => {
          let activite: any = [];
          activite.push({ "id": activiteSingle, "name": activiteObj.data.name });
          let dataa = {
            "id": id,
            "name": name,
            "function": fonction,
            "activites": activite,
            "profil": { "id": profil }
          };
          console.log("==================== data to insert =", dataa);
          let headers = new HttpHeaders();
          headers.append('Content-Type', 'text/plain;charset=UTF-8');
          headers.append('Content-Type', 'application/json; charset=utf-8');
          headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
          this.http.post('utilisateur/modification', dataa, { headers: headers }).subscribe(data => {
            resolve(data);
          }, err => {
            console.log("================== erreur dans ajout :", err);
          });
        });
      });
    }
    else {
      let dataa = {
        "id": id,
        "name": name,
        "function": fonction,
        "activites": activiteMultiple,
        "profil": { "id": profil }
      };
      console.log("==================== data to insert =", dataa);
      return new Promise(resolve => {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'text/plain;charset=UTF-8');
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
        this.http.post('utilisateur/modification', dataa, { headers: headers }).subscribe(data => {
          resolve(data);
        }, err => {
          console.log(err);
        });
      });
    }
  }
  activation(id, activate) {
    let dataa = {
      "id": id,
      "activate": activate
    };
    return new Promise(resolve => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'text/plain;charset=UTF-8');
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
      this.http.post('utilisateur/activation', dataa, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  suppression(id) {
    let dataa = {
      "id": id
    };
    return new Promise(resolve => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'text/plain;charset=UTF-8');
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
      this.http.post('utilisateur/supression', dataa, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  modificationmdp(id, email, password) {
    let dataa = {
      "id": id,
      "mail": email,
      "password": password
    };
    return new Promise(resolve => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'text/plain;charset=UTF-8');
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
      this.http.post('utilisateur/changemotdepasse', dataa, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  changeId(postId: number) {
    this.postIdSource.next(postId);
  }
  ajoutHistorique(data, datedebut, datefin) {
    return new Promise(resolve => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'text/plain;charset=UTF-8');
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
      this.http.post('historiquesession/ajout/' + datedebut + '/' + datefin, data, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  countReport(datedebut, datefin, data) {
    let url = 'historiquesession/count/';
    if (datedebut != null && datefin != null) {
      url = url + datedebut + '/' + datefin;
    }
    if (datedebut == null && datefin != null) {
      url = url + 'null/' + datefin;
    }
    if (datedebut != null && datefin == null) {
      url = url + datedebut + '/null';
    }
    if (datedebut == null && datefin == null) {
      url = url + 'null' + '/null';
    }
    return new Promise(resolve => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'text/plain;charset=UTF-8');
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
      this.http.post(url, data, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  report(data, datedebut, datefin, first, max) {
    let url = 'historiquesession/recherche/';
    if (datedebut != null && datefin != null) {
      url = url + datedebut + '/' + datefin + '/' + first + '/' + max;
    }
    if (datedebut == null && datefin != null) {
      url = url + 'null/' + datefin + '/' + first + '/' + max;
    }
    if (datedebut != null && datefin == null) {
      url = url + datedebut + '/null/' + first + '/' + max;
    }
    if (datedebut == null && datefin == null) {
      url = url + 'null' + '/null/' + first + '/' + max;
    }
    return new Promise(resolve => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'text/plain;charset=UTF-8');
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
      this.http.post(url, data, { headers: headers }).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  export(datedebut, datefin, dataa) {
    let url = 'historiquesession/export/';
    if (datedebut != null && datefin != null) {
      url = url + datedebut + '/' + datefin;
    }
    if (datedebut == null && datefin != null) {
      url = url + 'null/' + datefin;
    }
    if (datedebut != null && datefin == null) {
      url = url + datedebut + '/null';
    }
    if (datedebut == null && datefin == null) {
      url = url + 'null' + '/null';
    }
    return new Promise(resolve => {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json; charset=utf-8');
      headers.append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      headers.append('responseType', 'arraybuffer');
      this.http.post(url, dataa, { headers: headers }).subscribe(data => {
        resolve(data);
        this.saveExcelToFileSystem(data);
      }, err => {
        console.log(err);
      });
    });
  }
  saveExcelToFileSystem(response) {
    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' });
    var datedebut = new Date();
    var date = this.datepipe.transform(datedebut, 'yyyy_MM_dd');
    FileSaver.saveAs(blob, 'Historique_export_' + date + EXCEL_EXTENSION);
  }
  exportUsers(dataa) {
    let url = 'utilisateur/export/';
    this.httpServ.postDataToExportExcel(url, dataa).subscribe(data => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' });
      var datedebut = new Date();
      var date = this.datepipe.transform(datedebut, 'yyyy_MM_dd');
      FileSaver.saveAs(blob, 'Utilisateurs_export_' + date + EXCEL_EXTENSION);
    }, err => {
      console.log(err);
    });
  }
  exporterHistoConnex(dataa) {
    let url = 'historiquesession/export/';
    if (dataa.datelogin != null && dataa.datelogout != null) {
      url = url + dataa.datelogin + '/' + dataa.datelogout;
    }
    if (dataa.datelogin == null && dataa.datelogout != null) {
      url = url + 'null/' + dataa.datelogout;
    }
    if (dataa.datelogin != null && dataa.datelogout == null) {
      url = url + dataa.datelogin + '/null';
    }
    if (dataa.datelogin == null && dataa.datelogout == null) {
      url = url + 'null' + '/null';
    }
    this.httpServ.postDataToExportExcel(url, { "utilisateur": dataa.utilisateur }).subscribe(data => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' });
      var datedebut = new Date();
      var date = this.datepipe.transform(datedebut, 'yyyy_MM_dd');
      FileSaver.saveAs(blob, 'Historique_connex_export_' + date + EXCEL_EXTENSION);
    }, err => {
      console.log(err);
    });
  }
  getValideursFromActivites(csvIdActivites: string) {
    return new Promise(resolve => {
      this.http.get('utilisateur/valideursFromActivites?idactivites=' + csvIdActivites).subscribe(data => {
        resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }
  getDroitAcces(idProfil): Observable<ProfilAcces[]> {
    return this.http.get<ProfilAcces[]>('profil/acces?idProfil=' + idProfil);
  }
}
