import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { User } from '../modele/user';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { DatePipe } from '@angular/common';
import { UtilisateurService } from '../pages/utilisateur/utilisateur.service';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private appService: AppService, public datepipe: DatePipe, public utilisateurService: UtilisateurService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>("authentification", { username, password }).pipe(map(user => {
      console.log("======================== dans callback authentification");
      console.log(JSON.stringify(user));
      if (user.status=="200") {
        const data=user.data;
        console.log("==================================== Utilisateur "+JSON.stringify(data));
        localStorage.setItem('loginad', username);
        localStorage.setItem('name', data.user.mail);
        localStorage.setItem('nom', data.user.nom);
        localStorage.setItem('id', data.user.id);
        let activites: string = "";
        let sep: string = "";
        for(let obj of data.user.activite) {
          activites += sep.concat(obj.id);
          sep = ",";
        }
        localStorage.setItem('idactivite', activites);
        // localStorage.setItem('idactivite', JSON.stringify(data.user.activite.id));
        //localStorage.setItem('responsable', data.user.activite.responsable);
        localStorage.setItem('idprofile', data.user.profil.id);
        localStorage.setItem('nomprofile', data.user.profil.name);
        if (data.user.idintervenant !== null) {
          localStorage.setItem('idintervenant', data.user.idintervenant);
        }
        localStorage.setItem('currentUser', JSON.stringify(data));
        let datedebut = new Date();
        let debut = this.datepipe.transform(datedebut, 'yyyy-MM-dd HH:mm:ss');
        let session = {
          "identifiant": localStorage.getItem('nom') + debut,
          "utilisateur": localStorage.getItem('nom')
        }
        localStorage.setItem('session', JSON.stringify(session));
        localStorage.setItem('datelogin', debut);
        sessionStorage.setItem('co', 'oui');
        this.currentUserSubject.next(data);
        this.appService.setUserLoggedIn(true);

      }
      return user;
    }));
  }

  logout() {
    // remove user from local storage to log user out

    if (localStorage.getItem('datelogin')) {
      let datefin = new Date();
      let fin = this.datepipe.transform(datefin, 'yyyy-MM-dd HH:mm:ss');
      let data = {
        "identifiant": localStorage.getItem('nom') + localStorage.getItem('datelogin'),
        "utilisateur": localStorage.getItem('nom')
      }
      this.utilisateurService.ajoutHistorique(data, localStorage.getItem('datelogin'), fin);
      localStorage.removeItem('session');
      localStorage.removeItem('datelogin');
    }
    localStorage.removeItem('currentUser');
    localStorage.removeItem('nom');
    localStorage.clear();
    this.appService.setUserLoggedIn(false);
    this.currentUserSubject.next(null);
    //location.reload(true);
  }
  /**private handleError (error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body. || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return Observable.throw(errMsg);
  }**/
}
