import {Injectable} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  ActivatedRoute
} from '@angular/router';
//import { Observable } from 'rxjs';
//import { AuthentificationService } from '../service/authentification.service';
import {AppService} from '../service/app.service';
import {ToastrService} from "ngx-toastr";
import {MenuService} from "../theme/components/menu/menu.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  public menuItems: Array<any>;

  constructor(
    private router: Router,
    private appService: AppService, private toastr: ToastrService, public activeRoute: ActivatedRoute
  ) {
    this.menuItems = JSON.parse(localStorage.getItem('menuItems'));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //const currentUser = this.authenticationService.currentUserValue;
    //if (currentUser) {
    // logged in so return true
    //    return true;
    //}
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      if(this.menuItems) {
        let nbmenu = this.menuItems.filter(value => value.routerLink === state.url);
        if (nbmenu.length > 0) {
          return true;
        } else {
          this.toastr.error('No access');
          return false;
        }
      }
      return true;
    } else {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }




    // not logged in so redirect to login page with the return url

  }
}
