import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(protected http: HttpClient) { }

    postDataToExportExcel(url, dataObject): Observable<any> {
        const httpOptions: Object = {
            headers: new HttpHeaders({
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Content-Type': 'application/json'
            }),
            'responseType': 'arraybuffer'
        };
        return this.http.post(url, dataObject, httpOptions).pipe(retry(1));
    }
}
