import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageServiceModule } from 'angular-webstorage-service';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule } from 'angular-calendar';
import { JwtModule } from '@auth0/angular-jwt';
import { routing } from './app.routing';
import { AppSettings } from './app.settings';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { JwtInterceptor } from './helpers/jwt-interceptor';
import { ErrorInterceptor } from './helpers/error-interceptor';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { PipesModule } from './theme/pipes/pipes.module';
import localeFr from '@angular/common/locales/mg';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './service/loader.service';
import { LoaderComponent } from './theme/components/loader/loader.component';
import { PagesModule } from './pages/pages.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingModule } from 'ngx-loading';
import {MenuService} from "./theme/components/menu/menu.service";

registerLocaleData(localeFr, 'mg');

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    StorageServiceModule,
    PipesModule,
    RouterModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDe_oVpi9eRSN99G4o6TwVjJbFBNr58NxE'
    }),
    CalendarModule,
    MatProgressSpinnerModule,
    LoadingModule,
    routing,
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'mg' },
    AppSettings,
    LoaderService,
    DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
