import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as data from '../../assets/data/configapi.json';
import { AuthentificationService } from '../service/authentification.service';
import { LoaderService } from '../service/loader.service';
import { finalize } from 'rxjs/operators';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    config: any = {};
    constructor(private http: HttpClient, public loaderService: LoaderService, private authenticationService: AuthentificationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let req = request.url;
        let currentUser = this.authenticationService.currentUserValue;
        const url = document.getElementsByTagName('base')[1].href;
        if (localStorage.getItem("baseUrl") == null)
            localStorage.setItem("baseUrl", url);
        if (!request.url.startsWith("http")) {
            request = request.clone({
                url: url + request.url
            });

        }

        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    'Authorization': `Bearer ${currentUser.token}`
                }
            });
        }
        /** else{

             request = request.clone({
                 setHeaders: {
                     'Authorization': `Bearer ${currentUser.token}`,
                     'Content-Type': 'multipart/form-data'
                 }
             });
             console.log("GGGGGGGG"+JSON.stringify(request));
         }**/
        this.loaderService.show();
        return next.handle(request).pipe(
            finalize(() => this.loaderService.hide()));
    }
    loadConfigFileAjaxSync() {
        return new Promise(resolve => {
            this.http.get('../../assets/data/configapi.json').subscribe(data => {
              resolve(data);
            }, err => {
              console.log(err);
            });
          });
    }

}
