import { Component, ViewEncapsulation, ViewChild, TemplateRef, ElementRef, AfterViewInit, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppService } from '../app/service/app.service';
import { DatePipe } from '@angular/common';
import { UtilisateurService } from './pages/utilisateur/utilisateur.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {


  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  public settings: Settings;
  public modalRef: BsModalRef;
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  constructor(public appSettings: AppSettings, private router: Router, private idle: Idle, private keepalive: Keepalive, private modalService: BsModalService, private appService: AppService, public datepipe: DatePipe, public utilisateurService: UtilisateurService) {
    this.settings = this.appSettings.settings;
    idle.setIdle(3600);
    idle.setTimeout(10);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      //console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = 'Votre session a expiré!';
      this.timedOut = true;
      //console.log(this.idleState);
      this.router.navigate(['/login']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      //console.log(this.idleState);
      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Vous seriez deconnecté de l\'application dans ' + countdown + ' seconds!'
      //console.log(this.idleState);
    });

    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());


    this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });




  }

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.appService.setUserLoggedIn(false);
    this.router.navigate(['/login']);
  }





  /* These following methods used for theme preview, you can remove this methods */

  // ngOnInit() {
  //     var demo = this.getParameterByName('demo');
  //     this.setLayout(demo);
  // }

  // private getParameterByName(name) {
  //     var url = window.location.href;
  //     name = name.replace(/[\[\]]/g, "\\$&");
  //     var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
  //         results = regex.exec(url);
  //     if (!results) return null;
  //     if (!results[2]) return '';
  //     return decodeURIComponent(results[2].replace(/\+/g, " "));
  // }

  // private setLayout(demo){
  //      switch (demo) {
  //         case "vertical-default":
  //             this.settings.theme.menu = 'vertical';
  //             this.settings.theme.menuType = 'default';
  //             break;
  //         case "vertical-compact":
  //             this.settings.theme.menu = 'vertical';
  //             this.settings.theme.menuType = 'compact';
  //             break;
  //         case "vertical-mini":
  //             this.settings.theme.menu = 'vertical';
  //             this.settings.theme.menuType = 'mini';
  //             break;
  //         case "horizontal-default":
  //             this.settings.theme.menu = 'horizontal';
  //             this.settings.theme.menuType = 'default';
  //             break;
  //         case "horizontal-compact":
  //             this.settings.theme.menu = 'horizontal';
  //             this.settings.theme.menuType = 'compact';
  //             break;
  //         case "horizontal-mini":
  //             this.settings.theme.menu = 'horizontal';
  //             this.settings.theme.menuType = 'mini';
  //             break;
  //         default:
  //             this.settings.theme.menu = 'vertical';
  //             this.settings.theme.menuType = 'default';
  //     }
  //     this.router.navigate(['/']);
  // }
  ngOnInit() {
    if (!sessionStorage.getItem('co')) {
      sessionStorage.setItem('co', 'oui');
    }
    if (!localStorage.getItem('datelogin') && localStorage.getItem('nom')) {
      let datedebut = new Date();
      let debut = this.datepipe.transform(datedebut, 'yyyy-MM-dd HH:mm:ss');
      let session = {
        "identifiant": localStorage.getItem('nom') + debut,
        "utilisateur": localStorage.getItem('nom')
      }
      localStorage.setItem('session', JSON.stringify(session));
      localStorage.setItem('datelogin', debut);
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  public doSomething($event) {
    console.log("do I see this?");
    //localStorage.removeItem('datelogin');
    //localStorage.removeItem('session');
    if (localStorage.getItem('datelogin')) {
      let datefin = new Date();
      let fin = this.datepipe.transform(datefin, 'yyyy-MM-dd HH:mm:ss');
      let data = {
        "identifiant": localStorage.getItem('nom') + localStorage.getItem('datelogin'),
        "utilisateur": localStorage.getItem('nom')
      }
      this.utilisateurService.ajoutHistorique(data, localStorage.getItem('datelogin'), fin);
      localStorage.removeItem('datelogin');
    }
    event.preventDefault();
    event.returnValue = false;
    return false;
  }
  ngOnDestroy(): void {
    console.log("MIDYY");
    sessionStorage.clear();
  }

}
